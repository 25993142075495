import React, { useEffect } from 'react';
import '../../styles/popup.scss';

const Popup = ({ message, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 5000); // Close the popup after 5 seconds

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <p>{message}</p>
                <button onClick={onClose} className="popup-button">Okay</button>
            </div>
        </div>
    );
};

export default Popup;
