import React, { useEffect, useRef } from 'react';
import useWebSocket from 'react-use-websocket';

export const NotificationWebSocket = ({ setNotifications }) => {
    const access_token = localStorage.getItem('access_token');
    const socketUrl = `wss://noisefilter.app/api/ws/notifications`;

    // Store the time when the page was loaded
    const pageLoadTime = useRef(Date.now());

    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
        shouldReconnect: () => true,
        onOpen: () => {
            console.log("WebSocket connection established.");
            sendMessage(JSON.stringify({ token: access_token }));
        },
        onError: (error) => {
            console.error("WebSocket error:", error);
        },
        onClose: () => {
            console.log("WebSocket connection closed.");
        },
    });

    const showNotification = (message) => {
        if (Notification.permission === "granted") {
            const notification = new Notification("New notifications", {
                body: message,
            });

            notification.onclick = () => {
                openOrFocusTab('httpss://noisefilter.app:443/home');
                notification.close(); // Close the notification after it's clicked
            };
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(permission => {
                if (permission === "granted") {
                    const notification = new Notification("New notifications", {
                        body: message,
                    });

                    notification.onclick = () => {
                        openOrFocusTab('httpss://noisefilter.app:443/home');
                        notification.close(); // Close the notification after it's clicked
                    };
                }
            });
        }
    };

    const openOrFocusTab = (url) => {
        const tabName = 'uniqueTabName'; // Assign a unique name to the tab

        // Attempt to open the tab, if it's already opened, just focus on it
        const existingTab = window.open(url, tabName);

        if (existingTab) {
            existingTab.focus();
        }
    };

    useEffect(() => {
        if (lastMessage !== null) {
            try {
                const parsedNotifications = JSON.parse(lastMessage.data);
                const newNotifications = Array.isArray(parsedNotifications) ? parsedNotifications : [parsedNotifications];

                const formattedNotifications = newNotifications.map(notification => ({
                    id: notification.id,
                    userId: notification.user_id,
                    eventId: notification.event_id || null,
                    text: notification.text,
                    read: notification.read,
                    timestamp: notification.timestamp, // Assuming a timestamp field exists
                }));

                if (formattedNotifications.length > 0) {
                    setNotifications(prev => [
                        ...formattedNotifications, // Prepend new notifications
                        ...prev, // Keep the old notifications below
                    ]);

                    // Show browser notification only if more than 5 seconds have passed since page load
                    const currentTime = Date.now();
                    if (currentTime - pageLoadTime.current > 5000) {
                        const notificationMessage = `You have ${formattedNotifications.length} new notifications`;
                        showNotification(notificationMessage);
                    }
                }
            } catch (error) {
                console.error("Error parsing notification data:", error);
            }
        }
    }, [lastMessage, readyState, setNotifications]);


    return null;
};
