import React, { useState } from 'react';
import SidebarLayout from '../page/SidebarLayout';
import AuthService from "../../webSocket/component/AuthService ";
import '../../styles/userProfile.scss';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Popup from '../../webSocket/component/Popup';
import useAuthCheck from "../../webSocket/component/useAuthCheck"; // Import the Popup component

const UserProfile = () => {
    useAuthCheck();

    const [username, setUsername] = useState(localStorage.getItem('username') || '');
    const [newUsername, setNewUsername] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [usernameErrorMessage, setUsernameErrorMessage] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [activeForm, setActiveForm] = useState('username'); // Set default form to 'username'
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [popupMessage, setPopupMessage] = useState(''); // State to handle popup messages
    const [unauthorizedAttempts, setUnauthorizedAttempts] = useState(0); // State to track unauthorized attempts

    const toggleOldPasswordVisibility = () => {
        setShowOldPassword(!showOldPassword);
    };

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleChangeUsername = async () => {
        if (newUsername.trim() === '') {
            setUsernameErrorMessage('Username cannot be empty.');
            return;
        }

        const jsonBody = JSON.stringify({ username: newUsername });
        console.log('Sending JSON for username change:', jsonBody);

        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(`https://noisefilter.app/api/users/me`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: jsonBody,
            });

            const responseData = await response.json();
            console.log('Username update response:', responseData);

            if (response.ok) {
                console.log('Username updated successfully');
                setUsername(newUsername);
                localStorage.setItem('username', newUsername); // Update username in localStorage
                setNewUsername('');
                setUsernameErrorMessage('');
                setPopupMessage('Username updated successfully!');
                setUnauthorizedAttempts(0);
            } else if (response.status === 422) {
                console.error('Validation Error:', responseData.detail);
                setUsernameErrorMessage('Validation error occurred. Please check your inputs.');
            } else if (response.status === 401) {
                console.error("Unauthorized, refreshing token...");
                const newToken = await AuthService.refreshToken();
                if (newToken) {
                    localStorage.setItem('access_token', newToken);
                    await handleChangeUsername();
                } else {
                    setUnauthorizedAttempts(prev => prev + 1);
                    if (unauthorizedAttempts > 1) {
                        AuthService.logout(); // Log out if unauthorized multiple times
                    }
                }
            } else {
                setUsernameErrorMessage('Failed to update username.');
            }
        } catch (error) {
            console.error('Error:', error);
            setUsernameErrorMessage('An error occurred while updating the username.');
        }
    };

    const handleChangePassword = async () => {
        if (password !== confirmPassword) {
            setPasswordErrorMessage('Passwords do not match.');
            return;
        }

        const jsonBody = JSON.stringify({
            old_password: oldPassword,
            new_password: password,
        });
        console.log('Sending JSON for password change:', jsonBody);

        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(`https://noisefilter.app/api/change_password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: jsonBody,
            });

            const responseData = await response.json();
            console.log('Password change response:', responseData);

            if (response.ok) {
                console.log('Password changed successfully');
                setOldPassword('');
                setPassword('');
                setConfirmPassword('');
                setPasswordErrorMessage('');
                setPopupMessage('Password changed successfully!');
                setUnauthorizedAttempts(0);
            } else if (response.status === 422) {
                console.error('Validation Error:', responseData.detail);
                setPasswordErrorMessage('Validation error occurred. Please check your inputs.');
            } else if (response.status === 401) {
                console.error("Unauthorized, refreshing token...");
                const newToken = await AuthService.refreshToken();
                if (newToken) {
                    localStorage.setItem('access_token', newToken);
                    await handleChangePassword();
                } else {
                    setUnauthorizedAttempts(prev => prev + 1);
                    if (unauthorizedAttempts > 1) {
                        AuthService.logout(); // Log out if unauthorized multiple times
                    }
                }
            } else {
                setPasswordErrorMessage('Failed to change password.');
            }
        } catch (error) {
            console.error('Error:', error);
            setPasswordErrorMessage('An error occurred while changing the password.');
        }
    };

    const handleUsernameSubmit = (event) => {
        event.preventDefault();
        handleChangeUsername();
    };

    const handlePasswordSubmit = (event) => {
        event.preventDefault();
        handleChangePassword();
    };

    const closePopup = () => {
        setPopupMessage('');
    };

    return (
        <SidebarLayout>
            <div className="user-profile-container">
                <div className="user-profile-content">
                    <h2 className="user-profile-title">User Profile</h2>

                    <div className="form-toggle-buttons">
                        <button
                            onClick={() => { setActiveForm('username'); setPopupMessage(''); }}
                            className={activeForm === 'username' ? 'active' : ''}
                        >
                            Change Username
                        </button>
                        <button
                            onClick={() => { setActiveForm('password'); setPopupMessage(''); }}
                            className={activeForm === 'password' ? 'active' : ''}
                        >
                            Change Password
                        </button>
                    </div>

                    {activeForm === 'username' && (
                        <form onSubmit={handleUsernameSubmit}>
                            <div className="form-group">
                                <label>New Username:</label>
                                <input
                                    type="text"
                                    value={newUsername}
                                    onChange={(e) => setNewUsername(e.target.value)}
                                    required
                                    className="form-input"
                                    placeholder="Enter username"
                                />
                            </div>
                            {usernameErrorMessage && (
                                <p className="error">{usernameErrorMessage}</p>
                            )}
                            <button className="form-button">Update Username</button>
                        </form>
                    )}

                    {activeForm === 'password' && (
                        <form onSubmit={handlePasswordSubmit}>
                            <div className="form-group">
                                <div className="password-input-container">
                                    <input
                                        type={showOldPassword ? "text" : "password"}
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        required
                                        className="form-input"
                                        placeholder="Old password"
                                    />
                                    <span onClick={toggleOldPasswordVisibility} className="password-toggle-icon">
                                        {showOldPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="password-input-container">
                                    <input
                                        type={showNewPassword ? "text" : "password"}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        className="form-input"
                                        placeholder="New password"
                                    />
                                    <span onClick={toggleNewPasswordVisibility} className="password-toggle-icon">
                                        {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="password-input-container">
                                    <input
                                        type={showConfirmPassword ? "text" : "password"}
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                        className="form-input"
                                        placeholder="Confirm password"
                                    />
                                    <span onClick={toggleConfirmPasswordVisibility} className="password-toggle-icon">
                                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                </div>
                            </div>
                            {passwordErrorMessage && (
                                <p className="error">{passwordErrorMessage}</p>
                            )}
                            <button type="submit" className="form-button">Change Password</button>
                        </form>
                    )}
                </div>

                {popupMessage && <Popup message={popupMessage} onClose={closePopup} />}
            </div>
        </SidebarLayout>
    );
};

export default UserProfile;
