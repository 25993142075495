import React from 'react';


export function TagList({ items, onRemove }) {
    return (
        <div className="usernames-list">
            {items.map((item, index) => (
                <div key={index} className="username-tag">
                    {item}
                    <button onClick={() => onRemove(item)} className="remove-btn">x</button>
                </div>
            ))}
        </div>
    );
}