import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import InputField from '../input/InputField';
import "./telegram.css";
import useFlexCenter from "../input/useFlexCenter";
import HeaderImagePhoto from "../icon/img_29.png";
import HeaderImage from "../icon/img_30.png";
import HeaderImageWhite from "../icon/noiseWhite.png";
import AuthService from "../webSocket/component/AuthService ";
import useAuthCheck from "../webSocket/component/useAuthCheck";

function ConnectTelegram() {
    useAuthCheck();

    const navigate = useNavigate();

    const [phone, setPhone] = useState("+");
    const [errorPhone, setErrorPhone] = useState("");
    const [phoneColor, setPhoneColor] = useState("");
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const access_token = localStorage.getItem('access_token');
    const storedUsername = localStorage.getItem('username');
    localStorage.setItem('username', storedUsername);

    const handleSendCode = async (e) => {
        e.preventDefault();
        const phoneNumber = parsePhoneNumberFromString(phone);


        if (phone.trim() === "+") {
            setErrorPhone("Please enter your phone number.");
            setPhoneColor("red");
            setHasError(true);
        } else if (!phoneNumber || (!phone.startsWith("+888") && !phoneNumber.isValid())) {
            setErrorPhone("Please enter a valid phone number.");
            setPhoneColor("red");
            setHasError(true);
        } else {
            setErrorPhone("");
            setPhoneColor("green");
            setHasError(false);
            setIsLoading(true);


        const sendCodeRequest = async (token) => {
                try {
                    const response = await fetch(`https://noisefilter.app/api/bot/get_code/${phoneNumber.number.replace('+', '')}`, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `bearer ${token}`
                        },
                        body: JSON.stringify({ phone: phoneNumber.number })
                    });

                    if (response.ok) {
                        navigate("/confirm-telegram", { state: { phone: phoneNumber.number.replace('+', ''), username: storedUsername } });

                    } else if (response.status === 401) {
                        console.error("Unauthorized, refreshing token...");
                        const newToken = await AuthService.refreshToken();
                        if (newToken) {
                            localStorage.removeItem('access_token');
                            localStorage.setItem('access_token', newToken);
                            await sendCodeRequest(newToken);
                        } else {
                            console.error("Token refresh failed, redirecting to login...");
                            setErrorPhone("Unauthorized, please log in again.");
                            setPhoneColor("red");
                            setHasError(true);
                        }
                    } else {
                        const errorData = await response.json();
                        setErrorPhone(`Error: ${errorData.detail?.[0]?.msg || 'Unknown error'}`);
                        setPhoneColor("red");
                        setHasError(true);
                    }
                } catch (error) {
                    setErrorPhone(`Error: ${error.message}`);
                    setPhoneColor("red");
                    setHasError(true);
                } finally {
                    setIsLoading(false);
                }
            };

            await sendCodeRequest(access_token);
        }
    };

    const handlePhoneChange = (e) => {
        let input = e.target.value;

        if (!input.startsWith("+")) {
            input = "+" + input.replace(/\+/g, "");
        }

        setPhone(input);
        setPhoneColor("");
    };

    useFlexCenter('flex-center');

    return (
        <>
            {!isLoading && (
                <div className="header-container-white-telegram">
                    <img src={HeaderImagePhoto} alt="Header" className="header-image-white"/>
                    <img src={HeaderImage} alt="Header" className="header-image"/>
                </div>
            )}

            <center>
                {isLoading && (
                    <img src={HeaderImageWhite} alt="Loading..." className="loading-indicator-telegram"/>
                )}
            </center>

            <div className="connect-telegram-card">
                <h2>Connect your Telegram</h2>
                <p>To proceed, you have to connect your Telegram account via SMS confirmation process.</p>
                <InputField
                    type="tel"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={handlePhoneChange}
                    borderColor={phoneColor}
                    error={errorPhone}
                />
                <button
                    className={`send-btn ${hasError ? 'error-margin' : ''}`}
                    onClick={handleSendCode}
                    disabled={isLoading}
                >
                    {isLoading ? 'Sending...' : 'Send code'}
                </button>
            </div>
        </>
    );
}

export default ConnectTelegram;
