import { useEffect } from "react";

const useFlexCenter = () => {
    useEffect(() => {
        document.body.classList.add('flex-center');
        return () => {
            document.body.classList.remove('flex-center');
        };
    }, []);
};

export default useFlexCenter;
