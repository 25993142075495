import React, { useState, useEffect, useCallback } from 'react';
import { FilterInput } from '../component/FilterInput';
import { TagList } from '../component/TagList';
import { Notifications } from "./Notifications";
import { History } from "./History";
import '../main.scss';
import '../../icon.scss'
import AuthService from "../component/AuthService ";
import useAuthCheck from "../component/useAuthCheck";
import {FilterWebSocketHandler} from "./FilterWebSocketHandler";
import {WebSocketManager} from "../WebSocketDemo";
import {debounce} from "@mui/material";

export function Filter({ onApplyFilters, setFilters}) {


    useAuthCheck();

    const [isVisible, setIsVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('notifications');
    const [username, setUsername] = useState('');
    const [chat, setChat] = useState('');
    const [chats, setChats] = useState([]);
    const [contains, setContains] = useState('');
    const [containsList, setContainsList] = useState([]);
    const [startsWith, setStartsWith] = useState('');
    const [startsWithList, setStartsWithList] = useState([]);
    const [usernames, setUsernames] = useState([]);
    const [saveButtonText, setSaveButtonText] = useState('Save Notifications');
    const [applyButtonText, setApplyButtonText] = useState('Apply');
    const [applied, setApplied] = useState(false);
    const [savedFilters, setSavedFilters] = useState([]);
    const [history, setHistory] = useState([]);

    const userId = localStorage.getItem('userId');
    const access_token = localStorage.getItem('access_token');
    const [activeFilters, setActiveFilters] = useState({
        usernames: [],
        chats: [],
        containsList: [],
        startsWithList: []
    });



    const [activeFiltersOne, setActiveFiltersOne] = useState({
        usernames: [],
        chats: [],
        containsList: [],
        startsWithList: []
    });








    const fetchFilters = useCallback(async (token) => {
        try {
            const response = await fetch(`https://noisefilter.app/api/filters/user/me`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `bearer ${token}`
                },
            });

            if (!response.ok) {
                if (response.status === 401) {
                    console.error("Unauthorized, refreshing token...");
                    const newToken = await AuthService.refreshToken();
                    if (newToken) {
                        localStorage.removeItem('access_token');
                        localStorage.setItem('access_token', newToken);
                        await fetchFilters(newToken);
                    } else {
                        console.error("Token refresh failed, redirecting to login...");
                        // Handle redirection to login or showing an error
                    }
                } else {
                    throw new Error('Network response was not ok');
                }
            } else {
                const data = await response.json();
                setHistory(data);
            }
        } catch (error) {
            console.error('Error fetching user filters:', error);
        }
    }, []);

    useEffect(() => {
        fetchFilters(access_token);
    }, [fetchFilters, userId]);

    const fetchNotifications = useCallback(async (token) => {
        try {
            const response = await fetch(`https://noisefilter.app/api/user_events/user/me`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `bearer ${token}`
                },
            });

            if (!response.ok) {
                if (response.status === 401) {
                    console.error("Unauthorized, refreshing token...");
                    const newToken = await AuthService.refreshToken();
                    if (newToken) {
                        localStorage.removeItem('access_token');
                        localStorage.setItem('access_token', newToken);
                        await fetchNotifications(newToken);
                    } else {
                        console.error("Token refresh failed, redirecting to login...");
                        // Handle redirection to login or showing an error
                    }
                } else {
                    throw new Error(`Network response was not ok: ${response.status}`);
                }
            } else {
                const data = await response.json();
                setSavedFilters(data);
            }
        } catch (error) {
            console.error('Error fetching user notifications:', error);
        }
    }, [userId]);

    useEffect(() => {
        fetchNotifications(access_token);
    }, [fetchNotifications]);

    const adjustMaxHeight = useCallback(() => {
        const baseHeight = isVisible ? 250 : 660; // Default desktop heights
        const mobileBaseHeight = isVisible ? 280 : 700; // Default mobile heights
        const tagCount = usernames.length + chats.length + containsList.length + startsWithList.length;

        // Decrease height by 20px per tag on desktop
        const adjustedHeight = baseHeight - tagCount * 20;
        const adjustedMobileHeight = mobileBaseHeight - tagCount * 20;

        // Apply adjusted height based on screen size
        if (window.innerWidth <= 768) {
            document.querySelector('.history-section').style.maxHeight = `${Math.max(100, adjustedMobileHeight)}px`;
        } else {
            document.querySelector('.history-section').style.maxHeight = `${Math.max(100, adjustedHeight)}px`;
        }
    }, [isVisible, usernames.length, chats.length, containsList.length, startsWithList.length]);

    useEffect(() => {
        adjustMaxHeight();
    }, [adjustMaxHeight]);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const addUsername = (selectedUsername = username) => {
        if (selectedUsername && !usernames.includes(selectedUsername)) {
            setUsernames([...usernames, selectedUsername]);
            setUsername(''); // Clear the input after adding
        }
    };


    const addChat = (selectedChat = chat) => {
        if (selectedChat && !chats.includes(selectedChat)) {
            setChats([...chats, selectedChat]);
            setChat('');
        }
    };

    const addContains = () => {
        if (contains && !containsList.includes(contains)) {
            setContainsList([...containsList, contains]);
            setContains('');
        }
    };

    const addStartsWith = () => {
        if (startsWith && !startsWithList.includes(startsWith)) {
            setStartsWithList([...startsWithList, startsWith]);
            setStartsWith('');
        }
    };

    const removeUsername = (usernameToRemove) => {
        setUsernames(usernames.filter((user) => user !== usernameToRemove));
    };

    const removeChat = (chatToRemove) => {
        setChats(chats.filter((chat) => chat !== chatToRemove));
    };

    const removeContains = (containsToRemove) => {
        setContainsList(containsList.filter((item) => item !== containsToRemove));
    };

    const removeStartsWith = (startsWithToRemove) => {
        setStartsWithList(startsWithList.filter((item) => item !== startsWithToRemove));
    };

    const applyFilter = async () => {
        // Log current input values

        // Add values to the corresponding lists before applying the filter
        if (username.trim()) {
            setUsernames((prev) => [...prev, username.trim()]);
        }
        if (chat.trim()) {
            setChats((prev) => [...prev, chat.trim()]);
        }
        if (contains.trim()) {
            setContainsList((prev) => [...prev, contains.trim()]);
        }
        if (startsWith.trim()) {
            setStartsWithList((prev) => [...prev, startsWith.trim()]);
        }

        // Clear the form inputs immediately after adding new values
        setUsername('');
        setChat('');
        setContains('');
        setStartsWith('');

        // Set active filters and log them
        setActiveFilters({
            usernames: [...usernames, username.trim()].filter(Boolean),
            chats: [...chats, chat.trim()].filter(Boolean),
            containsList: [...containsList, contains.trim()].filter(Boolean),
            startsWithList: [...startsWithList, startsWith.trim()].filter(Boolean),
        });


        // Use the latest state values in your fetch and filter logic
        const updatedUsernames = username.trim() ? [...usernames, username.trim()] : usernames;
        const updatedChats = chat.trim() ? [...chats, chat.trim()] : chats;
        const updatedContainsList = contains.trim() ? [...containsList, contains.trim()] : containsList;
        const updatedStartsWithList = startsWith.trim() ? [...startsWithList, startsWith.trim()] : startsWithList;

        const filters = {
            username: updatedUsernames,
            chat_title: updatedChats,
            content: updatedContainsList,
            startswith: updatedStartsWithList
        };

        try {
            const response = await fetch('https://noisefilter.app/api/filters/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${access_token}`
                },
                body: JSON.stringify(filters),
            });

            // Check if the response is not OK
            if (!response.ok) {
                const errorText = await response.text();
                if (response.status === 401) {
                    console.error("Unauthorized, refreshing token...");
                    const newToken = await AuthService.refreshToken();
                    if (newToken) {
                        localStorage.removeItem('access_token');
                        localStorage.setItem('access_token', newToken);
                        await applyFilter(newToken);
                    } else {
                        console.error("Token refresh failed, redirecting to login...");
                    }
                } else if (response.status === 400 && errorText.includes("Duplicate event already exists.")) {
                    console.warn("Filter already exists, not adding to history...");
                    setApplyButtonText('✔ Already Applied');
                    setApplied(true);

                    setTimeout(() => {
                        setApplyButtonText('Apply');
                        setApplied(false);
                    }, 2500);
                    return; // Exit function to prevent further execution
                } else {
                    throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
                }
            } else {
                const data = await response.json();

                const filterWithId = { ...filters };

                // Only add the filter to history if it was successfully applied
                onApplyFilters(filterWithId);
                setHistory([...history, filterWithId]);


                setApplyButtonText('✔ Applied');
                setApplied(true);

                setTimeout(() => {
                    setApplyButtonText('Apply');
                    setApplied(false);
                }, 2500);
            }

            console.log('Fetching more messages with filters:', filters);

        } catch (error) {
            console.error('Error during applyFilter execution:', error);
        }
    };



    const saveNotifications = async () => {
        // Check for duplicates before adding or proceeding
        if (username.trim() && usernames.includes(username.trim())) {
            return;
        }
        if (chat.trim() && chats.includes(chat.trim())) {
            return;
        }
        if (contains.trim() && containsList.includes(contains.trim())) {
            return;
        }
        if (startsWith.trim() && startsWithList.includes(startsWith.trim())) {
            return;
        }

        // Create filters object only with non-empty arrays
        const filters = {};
        if (username.trim() || usernames.length > 0) {
            filters.username = username.trim() ? [...usernames, username.trim()] : usernames;
        }
        if (chat.trim() || chats.length > 0) {
            filters.chat_title = chat.trim() ? [...chats, chat.trim()] : chats;
        }
        if (contains.trim() || containsList.length > 0) {
            filters.content = contains.trim() ? [...containsList, contains.trim()] : containsList;
        }
        if (startsWith.trim() || startsWithList.length > 0) {
            filters.startswith = startsWith.trim() ? [...startsWithList, startsWith.trim()] : startsWithList;
        }

        // If the filters object is empty, do not proceed
        if (Object.keys(filters).length === 0) {
            console.warn("No valid filters to save.");
            return;
        }

        try {
            const response = await fetch('https://noisefilter.app/api/user_events/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${access_token}`
                },
                body: JSON.stringify(filters),
            });

            if (!response.ok) {
                const errorText = await response.text();
                if (response.status === 401) {
                    console.error("Unauthorized, refreshing token...");
                    const newToken = await AuthService.refreshToken();
                    if (newToken) {
                        localStorage.removeItem('access_token');
                        localStorage.setItem('access_token', newToken);
                        await saveNotifications(newToken);
                    } else {
                        console.error("Token refresh failed, redirecting to login...");
                        // Handle redirect to login or show error message
                    }
                } else if (response.status === 400 && errorText.includes("Duplicate event already exists.")) {
                    console.error("Error: Duplicate event already exists.");
                    // Log the error and return without updating the state
                    return;
                } else {
                    throw new Error('Network response was not ok');
                }
            } else {
                const data = await response.json();
                console.log('Success:', data);

                // Ensure that the filter is not added if a duplicate event exists
                const isDuplicate = data.error && data.error.includes("Duplicate event already exists.");
                if (!isDuplicate) {
                    // Update the state only after a successful POST request
                    setSavedFilters([...savedFilters, {...filters, id: data.id}]);

                    // Set button text to '✔ Saved' and revert to 'Save Notifications' after 2.5 seconds
                    setSaveButtonText('✔ Saved');
                    setTimeout(() => {
                        setSaveButtonText('Save Notifications');
                    }, 2500);
                }
            }

            // Clear the form inputs only if successful
            setUsername('');
            setChat('');
            setContains('');
            setStartsWith('');


        } catch (error) {
            console.error('Error:', error);
        }
    };


    const onRefresh = (filter) => {
        // Flatten the username array if it's nested
        const flatUsername = Array.isArray(filter.username)
            ? (Array.isArray(filter.username[0]) ? filter.username.flat(1) : filter.username)
            : [];

        // Викликати функції для оновлення без додавання нового фільтра в історію
        setUsernames(flatUsername);
        setChats(filter.chat_title || []);
        setContainsList(filter.content || []);
        setStartsWithList(filter.startswith || []);

        // Викликаємо логіку для повторного застосування фільтра
    };


    const [filtersTwo, setFiltersTwo] = useState({
        username: null,
        chat_title: null,
        content: null,
        startswith: null,
    });


    const handleResetFilters = () => {

        onApplyFilters({
            username: null,
            chat_title: null,
            content: null,
            startswith: null,
        });

        // Clear all filter-related state variables
        setUsername('');
        setUsernames([]);
        setChat('');
        setChats([]);
        setContains('');
        setContainsList([]);
        setStartsWith('');
        setStartsWithList([]);


        // Clear active filters state
        setActiveFilters({
            usernames: [],
            chats: [],
            containsList: [],
            startsWithList: [],
        });


        // Reset filters object
        const resetFilters = {
            command: 'reset_filters' // Add the reset command
        };


        // Send reset command via WebSocket
        WebSocketManager.send(JSON.stringify(resetFilters));


        // Update filters state in Home component
        setFilters({
            username: null,
            chat_title: null,
            content: null,
            startswith: null,
        });
    };




    return (
        <div className={`filter-container ${isVisible ? 'visible' : 'hidden'}`}>
            <div className="filter-header">
                <span className="filter-label" onClick={toggleVisibility}>Filter</span>
                <button className="toggle-btn" onClick={toggleVisibility}>
                    <img
                        src="../../icon/img_20.png" alt="Header" className="filter-image"/>
                </button>
            </div>
            {isVisible && (
                <div className="filter-section">
                    <div className="filter-options">
                        <FilterInput
                            placeholder="Username"
                            value={username}
                            setValue={setUsername}
                            onAdd={addUsername}
                            isAutoComplete={true} // Enable autocomplete only for chat title
                            fetchType="username" // Fetch chat titles
                        />
                        <TagList items={usernames} onRemove={removeUsername}/>

                        <FilterInput
                            placeholder="Contains"
                            value={contains}
                            setValue={setContains}
                            onAdd={addContains}
                        />
                        <TagList items={containsList} onRemove={removeContains}/>

                        <FilterInput
                            placeholder="Starts with"
                            value={startsWith}
                            setValue={setStartsWith}
                            onAdd={addStartsWith}
                        />
                        <TagList items={startsWithList} onRemove={removeStartsWith}/>

                        <FilterInput
                            placeholder="Chat title"
                            value={chat}
                            setValue={setChat}
                            onAdd={addChat}
                            isAutoComplete={true} // Enable autocomplete only for chat title
                            fetchType="chat" // Fetch chat titles
                        />
                        <TagList items={chats} onRemove={removeChat}/>
                    </div>
                    <div className="channel-messages-option">
                        <input type="checkbox" className="custom-checkbox"/>
                        <span>Show Channel Messages</span>
                    </div>
                    <button className={`notification-btn ${saveButtonText === 'Saved' ? 'saved-btn' : ''}`}
                            onClick={saveNotifications}>
                        {saveButtonText}
                    </button>
                    <button
                        className={`apply-btn ${applied ? 'applied' : ''}`}
                        onClick={applyFilter}
                    >
                        {applyButtonText}
                    </button>
                    <button className="reset-btn" onClick={handleResetFilters}>Reset</button>
                </div>
            )}
            {/* Show notify and active filters only on home page and if there are active filters */}
            {window.location.pathname === '/home' && (
                (activeFilters.usernames.length > 0 ||
                    activeFilters.chats.length > 0 ||
                    activeFilters.containsList.length > 0 ||
                    activeFilters.startsWithList.length > 0) && (
                    <>
                        <div className={`notify ${isVisible ? 'notify-visible' : 'notify-hidden'}`}></div>

                        <div className="active-filters-section">
                            <button className="active-filters-header">
                                Active Filters
                            </button>
                            <div className="active-filters-content">
                                {activeFilters.usernames.length > 0 && (
                                    <div className="filter-item">
                                        <span>[users]:</span> <span
                                        className="filter-value">{activeFilters.usernames.join(', ')}</span>
                                    </div>
                                )}
                                {activeFilters.chats.length > 0 && (
                                    <div className="filter-item">
                                        <span>[chats]:</span> <span
                                        className="filter-value">{activeFilters.chats.join(', ')}</span>
                                    </div>
                                )}
                                {activeFilters.containsList.length > 0 && (
                                    <div className="filter-item">
                                        <span>[contains]:</span> <span
                                        className="filter-value">{activeFilters.containsList.join(', ')}</span>
                                    </div>
                                )}
                                {activeFilters.startsWithList.length > 0 && (
                                    <div className="filter-item">
                                        <span>[starts with]:</span> <span
                                        className="filter-value">{activeFilters.startsWithList.join(', ')}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )
            )}


            <div className={`notify ${isVisible ? 'notify-visible' : 'notify-hidden'}`}></div>
            <button
                className={`tab-btn ${activeTab === 'notifications' ? 'notifications-active' : ''}`}
                onClick={() => setActiveTab('notifications')}
            >
                <span className="dot purple"></span>
                Active Notifications
            </button>
            <button
                className={`tabNotification ${activeTab === 'history' ? 'history-active' : ''}`}
                onClick={() => setActiveTab('history')}
            >
                <span className="dot green"></span>
                History
            </button>
            <div
                className={`history-section ${
                    isVisible && (activeFilters.usernames.length > 0 || activeFilters.chats.length > 0 || activeFilters.containsList.length > 0 || activeFilters.startsWithList.length > 0)
                        ? 'hidden filter-open'
                        : isVisible
                            ? 'filter-open'
                            : 'filter-closed'
                }`}
            >
                {activeTab === 'notifications' && (
                    <Notifications savedFilters={savedFilters} setSavedFilters={setSavedFilters}/>
                )}
                {activeTab === 'history' && (
                    <History
                        history={history}
                        setHistory={setHistory}
                        onRefresh={onRefresh}
                        toggleVisibility={toggleVisibility}
                        isFilterVisible={isVisible}
                    />
                )}
            </div>


        </div>
    );
}