import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import InputField from '../../input/InputField';
import "../telegram.css";
import useFlexCenter from "../../input/useFlexCenter";
import HeaderImagePhoto from "../../icon/img_29.png";
import HeaderImage from "../../icon/img_30.png";
import HeaderImageWhite from "../../icon/noiseWhite.png";
import AuthService from "../../webSocket/component/AuthService ";
import useAuthCheck from "../../webSocket/component/useAuthCheck";

function Password() {
    useAuthCheck();

    const location = useLocation();
    const navigate = useNavigate();

    // Retrieve phone and username from location.state or localStorage
    const [phone, setPhone] = useState(location.state?.phone || "");
    const [username, setUsername] = useState(location.state?.username || localStorage.getItem('username') || "");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const access_token = localStorage.getItem('access_token');

    // Fallback for username from localStorage
    useEffect(() => {
        if (!username) {
            const storedUsername = localStorage.getItem('username');
            if (storedUsername) {
                setUsername(storedUsername);
            }
        }
    }, [username]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleConfirm = async () => {
        setIsLoading(true); // Start loading

        const confirmPasswordRequest = async (token) => {
            const url = `https://noisefilter.app/api/bot/password/`; // Update the URL if necessary

            const requestData = {
                phone: phone,
                password: password,
                username: username
            };

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        'Authorization': `bearer ${token}`
                    },
                    body: JSON.stringify(requestData) // Convert the data to a JSON string
                });

                if (response.ok) {
                    const data = await response.json();
                    setResponseMessage("Connection successful: " + data.message);
                    navigate("/congratulations");  // Redirect to congratulations page
                } else if (response.status === 422) {
                    const errorData = await response.json();
                    setResponseMessage("Validation error: " + JSON.stringify(errorData.detail));
                } else if (response.status === 401) {
                    console.error("Unauthorized, refreshing token...");
                    const newToken = await AuthService.refreshToken();
                    if (newToken) {
                        localStorage.removeItem('access_token');
                        localStorage.setItem('access_token', newToken);
                        await confirmPasswordRequest(newToken);
                    } else {
                        console.error("Token refresh failed, redirecting to login...");
                        setResponseMessage("Unauthorized, please log in again.");
                    }
                } else {
                    setResponseMessage("Error: " + response.statusText);
                }
            } catch (error) {
                setResponseMessage("Fetch error: " + error.message);
            } finally {
                setIsLoading(false); // End loading
            }
        };

        await confirmPasswordRequest(access_token);
    };

    useFlexCenter('flex-center');

    return (
        <>
            {!isLoading && (
                <div className="header-container-white-telegram">
                    <img src={HeaderImagePhoto} alt="Header" className="header-image-white"/>
                    <img src={HeaderImage} alt="Header" className="header-image"/>
                </div>
            )}

            <center>
                {isLoading && (
                    <img src={HeaderImageWhite} alt="Loading..." className="loading-indicator"/>
                )}
            </center>

            <div className="confirm-telegram-card">
                <h2>Connect Telegram</h2>
                <p>Please enter your details below</p>

                <InputField
                    type="text"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />

                <InputField
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />

                <InputField
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    showPassword={showPassword}
                    togglePasswordVisibility={togglePasswordVisibility}
                />

                <div className="button-group">
                    <button className="send-btn" onClick={handleConfirm} disabled={isLoading}>
                        {isLoading ? "Processing..." : "Confirm"}
                    </button>
                </div>

                {responseMessage && <p>{responseMessage}</p>}
            </div>
        </>
    );
}

export default Password;
