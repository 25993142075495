import React from 'react';
import '../main.scss';
import '../../icon.scss';
import AuthService from "../component/AuthService ";
import useAuthCheck from "../component/useAuthCheck";

export function History({ history = [], setHistory, onRefresh, toggleVisibility, isFilterVisible }) {

    useAuthCheck();


    const access_token = localStorage.getItem('access_token');

    // Helper function to remove duplicates based on filter content
    const uniqueHistory = history.filter((filter, index, self) =>
            index === self.findIndex((f) =>
                f.username?.join(", ") === filter.username?.join(", ") &&
                f.chat_title?.join(", ") === filter.chat_title?.join(", ") &&
                f.content?.join(", ") === filter.content?.join(", ") &&
                f.startswith?.join(", ") === filter.startswith?.join(", ")
            )
    );

    // Filter out empty filters
    const filteredHistory = uniqueHistory.filter(filter => {
        return (
            filter.username?.length > 0 ||
            filter.chat_title?.length > 0 ||
            filter.content?.length > 0 ||
            filter.startswith?.length > 0
        );
    });

    const handleDelete = async (eventId) => {
        console.log('Deleting event with id:', eventId);
        if (!eventId) {
            console.error('Event ID is undefined');
            return;
        }

        const deleteFilter = async (token) => {
            try {
                const response = await fetch(`https://noisefilter.app/api/filters/${eventId}`, {
                    method: 'DELETE',
                    headers: {
                        'Accept': '*/*',
                        'Authorization': `bearer ${token}`
                    },
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        console.error("Unauthorized, refreshing token...");
                        const newToken = await AuthService.refreshToken();
                        if (newToken) {
                            localStorage.removeItem('access_token');
                            localStorage.setItem('access_token', newToken);
                            await deleteFilter(newToken);
                        } else {
                            console.error("Token refresh failed, redirecting to login...");
                            // Handle redirection to login or showing an error
                        }
                    } else {
                        throw new Error('Network response was not ok');
                    }
                } else {
                    setHistory(filteredHistory.filter((filter) => filter.id !== eventId));
                }
            } catch (error) {
                console.error('Error deleting user event:', error);
            }
        };

        await deleteFilter(access_token);
    };

    const handleRefresh = (filter) => {
        if (!isFilterVisible) {
            toggleVisibility(); // Only toggle visibility if the filter is not already visible
        }
        onRefresh(filter);
    };

    const formatValue = (value) => {
        if (Array.isArray(value)) {
            return value.map(val => `${val}`).join(", ");
        }
        return `${value}` || "";
    };

    return (
        <div className="history-section">
            {filteredHistory.length > 0 ? (
                filteredHistory.map((filter, index) => (
                    <div key={index} className="history-card">
                        <div className="card-header">
                            {filter.username?.length > 0 && (
                                <span className="user-icon">
                                    [users]: <span className="username-icon">{formatValue(filter.username)}</span>
                                </span>
                            )}
                            <img
                                src="../../icon/img_22.png" // Default (light theme)
                                alt="Refresh"
                                className="refresh-icon"
                                onClick={() => handleRefresh(filter)}
                            />
                            <img
                                src="../../icon/img_21.png" // Default (light theme)
                                alt="Delete"
                                className="delete-icon"
                                onClick={() => handleDelete(filter.id)}
                            />
                        </div>
                        <div className="card-content">
                            {filter.chat_title?.length > 0 && (
                                <p><strong>[Chat Titles]:</strong> {formatValue(filter.chat_title)}</p>
                            )}
                            {filter.content?.length > 0 && (
                                <p><strong>[Contains]:</strong> {formatValue(filter.content)}</p>
                            )}
                            {filter.startswith?.length > 0 && (
                                <p><strong>[Starts with]:</strong> {formatValue(filter.startswith)}</p>
                            )}
                        </div>
                    </div>
                ))
            ) : (
                <div className="no-history-message">No filters applied yet.</div>
            )}
        </div>
    );
}
