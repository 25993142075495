import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import PeopleChat from "../icon/img_10.png";
import PeopleGroups from "../icon/img_11.png";
import Channel from "../icon/img_12.png";
import './main.scss';
import AuthService from "./component/AuthService ";

export function SidebarRight() {
    const [privateChats, setPrivateChats] = useState([]);
    const [chats, setChats] = useState([]);
    const [channels, setChannels] = useState([]);
    const [error, setError] = useState(null);
    const [isPrivateChatsOpen, setPrivateChatsOpen] = useState(false);
    const [isChatsOpen, setChatsOpen] = useState(false);
    const [isChannelsOpen, setChannelsOpen] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);

    const sidebarRef = useRef(null);
    let scrollTimeout = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolling(true);
            clearTimeout(scrollTimeout.current);
            scrollTimeout.current = setTimeout(() => {
                setIsScrolling(false);
            }, 1000); // 1-second delay
        };

        const handleUserInteraction = () => {
            setIsScrolling(true);
            clearTimeout(scrollTimeout.current);
            scrollTimeout.current = setTimeout(() => {
                setIsScrolling(false);
            }, 1000); // 1-second delay
        };

        const sidebar = sidebarRef.current;
        if (sidebar) {
            sidebar.addEventListener('scroll', handleScroll);
            sidebar.addEventListener('wheel', handleUserInteraction);
            sidebar.addEventListener('mousemove', handleUserInteraction);
        }

        return () => {
            if (sidebar) {
                sidebar.removeEventListener('scroll', handleScroll);
                sidebar.removeEventListener('wheel', handleUserInteraction);
                sidebar.removeEventListener('mousemove', handleUserInteraction);
            }
        };
    }, [isScrolling]);

    useEffect(() => {
        if (isScrolling) {
            sidebarRef.current.classList.add('scrolling');
        } else {
            sidebarRef.current.classList.remove('scrolling');
        }
    }, [isScrolling]);

    useEffect(() => {
        const fetchChats = async (token) => {
            try {
                const response = await fetch(`https://noisefilter.app/api/chats_all/user/me`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        console.error("Unauthorized, refreshing token...");
                        const newToken = await AuthService.refreshToken();
                        if (newToken) {
                            localStorage.removeItem('access_token');
                            localStorage.setItem('access_token', newToken);
                            await fetchChats(newToken);
                        } else {
                            console.error("Token refresh failed, redirecting to login...");
                            // Handle redirection to login or showing an error
                            setError("Unauthorized, please log in again.");
                        }
                    } else {
                        throw new Error('Network response was not ok');
                    }
                } else {
                    const data = await response.json();
                    const privateChats = data.filter(chat => chat.chat_type === "Private chat");
                    const chats = data.filter(chat => chat.chat_type === "Group");
                    const channels = data.filter(chat => chat.chat_type === "Channel");
                    setPrivateChats(privateChats);
                    setChats(chats);
                    setChannels(channels);
                }
            } catch (error) {
                setError(error.message);
            }
        };

        const access_token = localStorage.getItem('access_token');
        fetchChats(access_token);
    }, []);

    const renderList = (items, itemType) => {
        const filtered = items.filter(chat => chat.chat_title !== null);
        if (!Array.isArray(filtered) || filtered.length === 0) {
            return <div>No {itemType} available</div>;
        }

        return filtered
            .filter(item => item.chat_username !== null)
            .map(item => {
                if (itemType === "private chats" && item.chat_title && item.chat_title.toLowerCase().endsWith("bot")) {
                    return null;
                }
                let linkClass;
                switch (itemType) {
                    case "private chats":
                        linkClass = "private-link";
                        break;
                    case "chats":
                        linkClass = "group-link";
                        break;
                    case "channels":
                        linkClass = "channel-link";
                        break;
                    default:
                        linkClass = "link";
                }
                return (
                    <div key={item.id} className={itemType.slice(0, -1)}>
                        <a href={`https://t.me/${item.chat_username}`} target="_blank" rel="noopener noreferrer"
                           className={linkClass}>
                            {item.chat_title}
                        </a>
                        {itemType === 'chats' && (
                            <span className="view-link" onClick={() => window.open(`https://t.me/${item.chat_username}`, '_blank', 'noopener,noreferrer')}>
                                View
                            </span>
                        )}
                    </div>
                );
            });
    };

    return (
        <div className="sidebar-right scrolling-container" ref={sidebarRef}>
            {error && <div className="error-message">{error}</div>}

            <div className="private-chats">
                <h4 onClick={() => setPrivateChatsOpen(!isPrivateChatsOpen)} style={{ cursor: 'pointer' }}>
                    <img src={PeopleChat} alt="Header" className="right-photo"/>
                    Private Chats
                    <span className="chevron-icon">
                        <FontAwesomeIcon icon={isPrivateChatsOpen ? faChevronUp : faChevronDown} />
                    </span>
                </h4>
                {isPrivateChatsOpen && <div className="scrollable-list">{renderList(privateChats, "private chats")}</div>}
            </div>

            <div className="groups">
                <h4 onClick={() => setChatsOpen(!isChatsOpen)} style={{ cursor: 'pointer' }}>
                    <img src={PeopleGroups} alt="Header" className="right-photo"/>
                    Groups
                    <span className="chevron-icon">
                        <FontAwesomeIcon icon={isChatsOpen ? faChevronUp : faChevronDown} />
                    </span>
                </h4>
                {isChatsOpen && <div className="scrollable-list">{renderList(chats, "chats")}</div>}
            </div>

            <div className="channels">
                <h4 onClick={() => setChannelsOpen(!isChannelsOpen)} style={{ cursor: 'pointer' }}>
                    <img src={Channel} alt="Header" className="right-photo"/>
                    Channels
                    <span className="chevron-icon">
                        <FontAwesomeIcon icon={isChannelsOpen ? faChevronUp : faChevronDown} />
                    </span>
                </h4>
                {isChannelsOpen && <div className="scrollable-list">{renderList(channels, "channels")}</div>}
            </div>
        </div>
    );
}
