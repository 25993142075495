import React, { useEffect, useRef, useState, forwardRef, useCallback, useImperativeHandle } from 'react';
import AuthService from "../component/AuthService ";
import DOMPurify from "dompurify";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import '../chat.scss';
import useAuthCheck from "../component/useAuthCheck";

export const ChatDelete = forwardRef(({ filters, appliedFilters }, ref) => {

    useAuthCheck();



    const containerRef = useRef(null);
    const loadMoreRef = useRef(null);
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { chats = [] } = filters;

    const makeLinksClickable = (text) => {
        const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        return text.replace(urlPattern, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
    };

    useEffect(() => {
        if (filters.startDateTimestamp && filters.endDateTimestamp && chats.length > 0) {
            fetchDeletedMessages(filters.startDateTimestamp, filters.endDateTimestamp);
        }
    }, [filters]);

    const fetchDeletedMessages = async (startDateTimestamp, endDateTimestamp, retries = 3) => {
        const access_token = localStorage.getItem('access_token');

        if (!startDateTimestamp || !endDateTimestamp || chats.length === 0) {
            console.error("Missing startDateTimestamp, endDateTimestamp, or chats are empty");
            return;
        }
        setMessages([]);  // Очистити повідомлення перед новим запитом

        setIsLoading(true);
        setError(null);  // Скинути стан помилки перед новим запитом

        const filtersPayload = {
            chats: chats,
            filters: {
                username: filters.username || null,
                content: filters.content || null,
                startswith: filters.startswith || null,
            }
        };

        const url = `https://noisefilter.app/api/bot/get_deleted/?start_timestamp=${startDateTimestamp}&end_timestamp=${endDateTimestamp}`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${access_token}`
                },
                body: JSON.stringify(filtersPayload),
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);

                const deletedMessagesArray = data.deleted_messages || [];
                const modifiedMessagesArray = data.modified_messages || {};

                // Обробка видалених повідомлень
                const processedDeletedMessages = deletedMessagesArray.flatMap(chat => {
                    const [chatId, messagesArray] = Object.entries(chat)[0]; // Отримуємо chatId і відповідний масив повідомлень
                    if (!Array.isArray(messagesArray)) {
                        console.error(`Очікував масив для видалених повідомлень у чаті ${chatId}, але отримав:`, messagesArray);
                        return [];
                    }
                    return messagesArray.map(item => ({
                        ...item,
                        id: `${item.id}-${chatId}`, // Унікальний ідентифікатор
                        chat_id: chatId,
                        type: 'deleted',
                        username: item.sender_username || 'Unknown',
                        group: item.chat_title || 'Unknown Group',
                        time: item.created_at ? new Date(item.created_at * 1000).toLocaleString() : 'Unknown Time',
                        body: item.text ? DOMPurify.sanitize(makeLinksClickable(item.text)) : 'No content available',
                    }));
                });

                // Обробка змінених повідомлень
                const processedModifiedMessages = Object.entries(modifiedMessagesArray).flatMap(([chatId, messagesArray]) => {
                    if (!Array.isArray(messagesArray)) {
                        console.error(`Очікував масив для змінених повідомлень у чаті ${chatId}, але отримав:`, messagesArray);
                        return [];
                    }
                    return messagesArray.map(item => ({
                        ...item,
                        id: `${item.id}-${chatId}`, // Унікальний ідентифікатор
                        chat_id: chatId,
                        type: 'modified',
                        username: item.sender_username || 'Unknown',
                        group: item.chat_title || 'Unknown Group',
                        time: item.updated_at ? new Date(item.updated_at * 1000).toLocaleString() : 'Unknown Time',
                        body: item.text ? DOMPurify.sanitize(makeLinksClickable(item.text)) : 'No content available',
                    }));
                });

                const combinedMessages = [...processedDeletedMessages, ...processedModifiedMessages];
                // Сортуємо повідомлення за датою (найсвіжіші зверху)
                combinedMessages.sort((a, b) => new Date(b.time) - new Date(a.time));

                if (combinedMessages.length === 0) {
                    setError(
                        <div style={{ textAlign: 'center', padding: '20px', color: '#00FFFF', fontSize: '18px' }}>
                            <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>No Notifications Found</p>
                            <p style={{ fontStyle: 'italic' }}>It seems there are no deleted or modified messages within the selected time range.</p>
                        </div>
                    );
                } else {
                    setMessages(combinedMessages);
                }

            } else if (response.status === 401) {
                const newToken = await AuthService.refreshToken();
                if (newToken) {
                    localStorage.removeItem('access_token');
                    localStorage.setItem('access_token', newToken);
                    await fetchDeletedMessages(startDateTimestamp, endDateTimestamp);
                } else {
                    console.error("Token refresh failed, redirecting to login...");
                }
            } else {
                const errorResponse = await response.json();
                console.error("Failed to fetch deleted messages:", response.statusText, errorResponse);
                setError(
                    <div style={{ textAlign: 'center', padding: '20px', color: '#00FFFF', fontSize: '18px' }}>
                        <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>Failed to Fetch Notifications</p>
                        <p style={{ fontStyle: 'italic' }}>Please try again later or adjust your filters.</p>
                    </div>
                );
            }
        } catch (error) {
            console.error("Error fetching deleted messages:", error);

            if (retries > 0) {
                setTimeout(() => fetchDeletedMessages(startDateTimestamp, endDateTimestamp, retries - 1), 1000);
            } else {
                setError(
                    <div style={{ textAlign: 'center', padding: '20px', color: '#00FFFF', fontSize: '18px' }}>
                        <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>Error Fetching Notifications</p>
                        <p style={{ fontStyle: 'italic' }}>Please try again later or contact support.</p>
                    </div>
                );
            }
        } finally {
            setIsLoading(false);
        }
    };


    useImperativeHandle(ref, () => ({
        fetchDeletedMessages,
        scrollToLastMessageDelete() {
            if (containerRef.current) {
                containerRef.current.scrollTop = containerRef.current.scrollHeight;
            }
        }
    }));




    const renderRow = useCallback((msg) => {
        const messageClass = msg.type === 'deleted' ? 'deleted' : msg.type === 'modified' ? 'modified' : '';

        return (
            msg && (
                <div key={msg.id} id={`message-${msg.id}`} className="cart-message-wrapper">
                    <div className={`chat-message ${messageClass}`}>
                        <div className="chat-header">
                            <span className="chat-username">{msg.username || 'Unknown'}</span>
                            <span className="chat-group">In {msg.type || 'Group'} {msg.group || 'Unknown Group'}</span>
                        </div>
                        <div className="chat-body">
                            <div dangerouslySetInnerHTML={{ __html: msg.body }}></div>
                        </div>
                        <div className="chat-time">{msg.time}</div>
                    </div>
                </div>
            )
        );
    }, []);

    return (
        <div id="scrollableDiv" className="chat-container-virtual-scroll" ref={containerRef}>
            {isLoading && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    marginTop: '-100px', // Adjusts the spinner's vertical position
                    zIndex: 1000,
                }}>
                    <CircularProgress size={80} /> {/* Increased size for visibility */}
                </Box>
            )}
            {error ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    {error}
                </Box>
            ) : (
                messages.map((msg) => (
                    <React.Fragment key={msg.id}>
                        {renderRow(msg)}
                    </React.Fragment>
                ))
            )}
            <div ref={loadMoreRef} style={{ height: '1px' }}></div>
        </div>
    );
});
