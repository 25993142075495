import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import '../../styles/sidebar.scss';
import useAuthCheck from "../../webSocket/component/useAuthCheck";

const SidebarLayout = ({ children }) => {

    useAuthCheck();

    const location = useLocation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const getLinkClassName = (path) => {
        return location.pathname === path ? 'menu-item active' : 'menu-item';
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className={`sidebar-layout ${isSidebarOpen ? 'sidebar-open' : ''}`}>
            <div className="sidebar-toggle" onClick={toggleSidebar}>
                <FontAwesomeIcon
                    icon={isSidebarOpen ? faChevronLeft : faChevronRight}
                    className="arrow-icon"
                />
            </div>
            <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <Link to="/settings" className={getLinkClassName('/settings')}>Telegram management</Link>
                <Link to="/accountManagement" className={getLinkClassName('/accountManagement')}>Account management</Link>
                <Link to="/userProfile" className={getLinkClassName('/userProfile')}>User Profile management</Link>
            </div>
            <div className={`content ${isSidebarOpen ? 'content-overflow' : ''}`}>
                {children}
            </div>
        </div>
    );
};

export default SidebarLayout;
