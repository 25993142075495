import React from "react";
import '../styles/pageOne.scss';

const PageOne = () => {
    return (
        <div className="content-container">
            <h1 className="page-title">Page Analytics</h1>
        </div>
    );
};

export default PageOne;
