import React from "react";
import "./ConfirmEmail.css";
import useFlexCenter from "../input/useFlexCenter";
import HeaderImagePhoto from '../icon/img_29.png'; // Adjust the path as needed
import HeaderImage from '../icon/img_30.png'; // Adjust the path as needed

function ConfirmEmail() {

    useFlexCenter('flex-center');

    return (
        <>
            <div className="header-container-white-telegram">
                <img src={HeaderImagePhoto} alt="Header" className="header-image-white"/>
                <img src={HeaderImage} alt="Header" className="header-image"/>
            </div>

            <div className="confirm-email-card">
                <h2>Confirm Email</h2>
                <p>We have sent a confirmation email to your address, please confirm by clicking the received link.</p>
            </div>
        </>
    );
}

export default ConfirmEmail;
