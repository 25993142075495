import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";

// Get the root element from the DOM
const container = document.getElementById("root");

// Create a root
const root = createRoot(container);

// Render the app
root.render(
    <Router>

        <App />
    </Router>
);
