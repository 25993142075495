import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./VerifyEmail.css";
import useFlexCenter from "../input/useFlexCenter";
import HeaderImagePhoto from "../icon/img_29.png";
import HeaderImage from "../icon/img_30.png";

function VerifyEmail() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useFlexCenter('flex-center');

    useEffect(() => {
        const userId = searchParams.get("user_id");
        const token = searchParams.get("token");
        const storedUsername = localStorage.getItem('username');

        const verifyEmail = async () => {
            try {
                const response = await fetch(`https://noisefilter.app/api/verify_email?user_id=${userId}&token=${token}`, {
                    method: "POST",
                });

                if (response.ok) {
                    const result = await response.json();
                    if (result.access_token && result.refresh_token) {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('refresh_token');


                        localStorage.setItem("access_token", result.access_token);
                        localStorage.setItem("refresh_token", result.refresh_token);

                        console.log("Tokens are stored in localStorage");

                        // Navigate to connect-telegram page after successful verification
                        navigate("/connect-telegram", { state: { username: storedUsername } });
                    } else {
                        setError("Invalid response from server.");
                    }
                } else {
                    const errorData = await response.json();
                    setError(errorData.detail || "An error occurred during email verification. Please try again.");
                }
            } catch (error) {
                setError("An error occurred during email verification. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        verifyEmail();
    }, [navigate, searchParams]);

    return (
        <>
            <div className="header-container-white-telegram">
                <img src={HeaderImagePhoto} alt="Header" className="header-image-white"/>
                <img src={HeaderImage} alt="Header" className="header-image"/>
            </div>

            <div className="confirm-email-card">
                {loading ? (
                    <div className="spinner"></div>
                ) : (
                    <div>
                        {error ? (
                            <p className="error">{error}</p>
                        ) : (
                            <>
                                <h2>Email verified successfully</h2>
                                <button className="submit-btn" onClick={() => navigate("/connect-telegram")}>Okay</button>
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}

export default VerifyEmail;
