import React, { useState, useEffect, useCallback } from 'react';
import './FilterInput.css';
import AuthService from "./AuthService ";

// FilterInput Component
export function FilterInput({ placeholder, value, setValue, onAdd, isAutoComplete, fetchType }) {
    const [suggestions, setSuggestions] = useState([]);
    const [showOverlay, setShowOverlay] = useState(false);

    const fetchSuggestions = useCallback(async (query) => {
        const token = localStorage.getItem('access_token');
        let apiUrl = '';

        if (query && isAutoComplete) {
            try {
                // Determine the URL based on fetchType (chat or username)
                if (fetchType === 'chat') {
                    apiUrl = `https://noisefilter.app/api/search_chats/?q=${query}`;
                } else if (fetchType === 'username') {
                    apiUrl = `https://noisefilter.app/api/get_usernames_by_user/?q=${query}`;
                }

                const response = await fetch(apiUrl, {
                    headers: {
                        'Authorization': `bearer ${token}`
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('Fetched data:', data);
                    const filteredData = data.filter(item => item !== value); // Modify filter logic based on data structure
                    setSuggestions(filteredData);
                } else if (response.status === 401) {
                    console.error("Unauthorized, refreshing token...");
                    const newToken = await AuthService.refreshToken();
                    if (newToken) {
                        localStorage.setItem('access_token', newToken);
                        fetchSuggestions(query); // Retry with new token
                    } else {
                        console.error("Token refresh failed, redirecting to login...");
                        // Handle redirection to login or error display
                    }
                } else {
                    console.error('Error fetching suggestions:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        } else {
            setSuggestions([]);
        }
    }, [isAutoComplete, value, fetchType]);

    useEffect(() => {
        const debounceFetch = setTimeout(() => {
            fetchSuggestions(value);
        }, 300);

        return () => clearTimeout(debounceFetch);
    }, [value, fetchSuggestions]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && value.trim()) {
            e.preventDefault();
            onAdd(value.trim());
            setSuggestions([]);
            setValue(''); // Clear input after adding
        }
    };

    const handleFocus = () => setShowOverlay(true);
    const handleBlur = () => setShowOverlay(false);

    const handleSelectSuggestion = (suggestion, index) => {
        console.log('Selected suggestion:', suggestion); // Log the selected suggestion

        setValue(''); // Очищуємо поле вводу

        // Викликаємо onAdd з переданим значенням та індексом вибраного елемента
        if (fetchType === 'chat') {
            onAdd(suggestion.chat_title, suggestion.id, index); // Додаємо назву чату та індекс
        } else if (fetchType === 'username') {
            onAdd(suggestion, index); // Додаємо ім'я користувача та індекс
        }

        setSuggestions([]); // Очищуємо список пропозицій після вибору
    };



    return (
        <div className="filter-input-section">
            {showOverlay && <div className="overlay-background" />}
            <div className="input-with-button">
                <input
                    type="text"
                    className="filter-input"
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                <button className="add-btn" onClick={() => {
                    if (value.trim()) {
                        onAdd(value.trim());
                        setValue(''); // Clear the input field after adding
                    }
                }}>
                    <img src="../../icon/img_23.png" alt="Header" className="add-btn-btn" />
                </button>
            </div>

            {isAutoComplete && suggestions.length > 0 && (
                <ul className="suggestions-list">
                    {suggestions.map((suggestion, index) => (
                        <li
                            key={suggestion.id || suggestion} // Для унікальності ключа
                            onClick={() => handleSelectSuggestion(suggestion, index)} // Передаємо вибраний елемент та його індекс
                        >
                            {fetchType === 'chat' ? suggestion.chat_title : suggestion} {/* Коригуємо залежно від типу */}
                        </li>
                    ))}

                </ul>
            )}
        </div>
    );
}
