import React, { useState, useEffect, useRef } from 'react';
import useWebSocket from 'react-use-websocket';
import DOMPurify from 'dompurify';
import { FilterWebSocketHandler } from './one/FilterWebSocketHandler';
import './main.scss';

export class WebSocketManager {
    static sendMessage = null;

    static initSendMessage(fn) {
        WebSocketManager.sendMessage = fn;
    }

    static send(msg) {
        if (WebSocketManager.sendMessage) {
            WebSocketManager.sendMessage(msg);
        } else {
            console.error('WebSocket not initialized yet.');
        }
    }
}

export const WebSocketDemo = ({
                                  setMessages,
                                  filters,
                                  activeTab,
                                  setUnreadCount,
                                  setFirstWebSocketMessageId,
                                  setLastWebSocketMessageId,
                              }) => {
    const access_token = localStorage.getItem('access_token');
    const socketUrl = `wss://noisefilter.app/api/ws/new_messages`;

    const { sendMessage, lastMessage } = useWebSocket(socketUrl, {
        shouldReconnect: () => true,
        onOpen: () => {
            if (activeTab === 'all') {
                sendMessage(JSON.stringify({ token: access_token }));
            }
        },
    });

    // Initialize the static sendMessage method
    useEffect(() => {
        WebSocketManager.initSendMessage(sendMessage);
    }, [sendMessage]);

    const firstMessageReceived = useRef(false); // To track if the first message has been received

    const makeLinksClickable = (text) => {
        const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#%?=~_|!:,.;]*[-A-Z0-9+&@#%=~_|])/ig;
        return text.replace(urlPattern, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
    };

    useEffect(() => {
        if (lastMessage !== null && activeTab === 'all') {
            try {
                const parsedMessages = JSON.parse(lastMessage.data);
                const newMessages = Array.isArray(parsedMessages) ? parsedMessages : [parsedMessages];

                newMessages.forEach((msg) => {
                    if (msg.id) {
                        console.log('New message ID (from WebSocket):', msg.id);
                    } else {
                        console.log('Message skipped due to missing ID');
                    }
                });

                const validMessages = newMessages.filter((msg) => msg.id);

                const formattedMessages = validMessages.map((msg) => {
                    const messageTime = msg.created_at
                        ? new Date(msg.created_at * 1000).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                        })
                        : '';

                    return {
                        id: msg.id,
                        username: msg.sender_username || 'Unknown',
                        group: msg.chat_title || 'Unknown Group',
                        body: msg.text
                            ? DOMPurify.sanitize(makeLinksClickable(msg.text))
                            : '',
                        type: msg.chat && msg.chat.chat_type ? msg.chat.chat_type : 'Group',
                        time: messageTime,
                        created_at: msg.created_at,
                    };
                });

                if (formattedMessages.length > 0) {
                    if (!firstMessageReceived.current) {
                        setFirstWebSocketMessageId(formattedMessages[0].id);
                        firstMessageReceived.current = true;
                        console.log('First WebSocket message ID:', formattedMessages[0].id);
                    }

                    setLastWebSocketMessageId(
                        formattedMessages[formattedMessages.length - 1].id
                    );

                    setMessages((prevMessages) => {
                        const uniqueMessages = formattedMessages.filter(
                            (newMsg) => !prevMessages.some((prevMsg) => prevMsg.id === newMsg.id)
                        );
                        return [...prevMessages, ...uniqueMessages];
                    });

                    setUnreadCount((prevCount) => prevCount + formattedMessages.length);
                }
            } catch (error) {
                console.error('Error parsing message data:', error);
            }
        }
    }, [
        lastMessage,
        setMessages,
        activeTab,
        setFirstWebSocketMessageId,
        setLastWebSocketMessageId,
        setUnreadCount,
    ]);

    const filterHandler = new FilterWebSocketHandler(sendMessage, setUnreadCount, activeTab);

    useEffect(() => {
        filterHandler.resetFilters(filters);
    }, [filters, activeTab, sendMessage]);

    return <div>{/* Optionally, display connection status */}</div>;
};