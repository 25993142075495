import React, { useState, useEffect } from 'react';
import '../main.scss';

export function HeaderChat({ setIsFilterVisible, setActiveTab }) {
    const [activeTab, setActiveTabState] = useState('all');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleTabClick = (tab) => {
        setActiveTabState(tab);
        setActiveTab(tab); // Pass the active tab to the Home component

    };

    const handleFilterClick = () => {
        setIsFilterVisible(prev => !prev); // Toggle filter visibility
        setActiveTabState('filter'); // Set the active tab to "filter"
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="header-chat">
            <div className="tabs">
                <div
                    className={`tab ${activeTab === 'all' ? 'active all' : ''}`}
                    onClick={() => handleTabClick('all')}
                >
                    <span className="dot blue"></span> All messages
                </div>
                <div
                    className={`tab ${activeTab === 'deleted' ? 'active deleted' : ''}`}
                    onClick={() => handleTabClick('deleted')}
                >
                    <span className="dot red"></span> {isMobile ? 'Deleted' : 'Deleted messages'}
                </div>
            </div>

            {/* Filter icon only for mobile version */}
            {isMobile && (
                <div
                    className="filter-icon-container"
                    onClick={handleFilterClick}
                >
                    <img src="../../icon/img_17.png" alt="Filter" className="filter-icon-mobile" />
                </div>
            )}
        </div>
    );
}
