import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import InputField from '../input/InputField'; // Імпорт компонента InputField
import "./register.css";
import HeaderImage from '../icon/img_30.png'; // Задайте правильний шлях до вашого файлу зображення
import HeaderImagePhoto from '../icon/img_29.png'; // Задайте правильний шлях до вашого файлу зображення
import HeaderImageWhite from '../icon/noiseWhite.png'; // Задайте правильний шлях до вашого файлу зображення
import useFlexCenter from '../input/useFlexCenter'; // Імпорт користувацького хука

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [emailColor, setEmailColor] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [confirmPasswordColor, setConfirmPasswordColor] = useState("");
  const [error, setError] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [username, setUsername] = useState("");
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorClass, setErrorClass] = useState(""); // New state for error class

  const navigate = useNavigate();

  useFlexCenter('flex-center');

  useEffect(() => {
    setUsername(email.split('@')[0]);
  }, [email]);

  function validate(e) {
    e.preventDefault();

    // Clear all errors first
    setError("");
    setErrorConfirmPassword("");
    setErrorEmail("");
    setErrorClass("");
    setHasError(false);

    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setErrorEmail("Invalid email address.");
      setEmailColor("red");
      setHasError(true);
      return;  // Exit early to prevent other errors from showing
    } else {
      setErrorEmail("");
      setEmailColor("green");
    }

    // Validate password and confirm password
    if (password !== "" && password === confirmPassword) {
      setErrorConfirmPassword("");
      setConfirmPasswordColor("green");
      setIsLoading(true);
      registerUser();
    } else {
      setErrorConfirmPassword("Passwords didn't match.");
      setConfirmPasswordColor("red");
      setHasError(true);
      return;  // Exit early to prevent other errors from showing
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const registerUser = async () => {
    try {
      setError("");
      setErrorClass("");
      setIsLoading(true);  // Start loading

      const response = await fetch('https://noisefilter.app/api/register/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify({
          username: username,
          password: password,
          email: email
        })
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Registration successful", data);
        localStorage.setItem('username', username);

        localStorage.setItem('userId', data.id); // Save id in local storage
        navigate("/confirm-email", { state: { username } }); // Pass username in state
      } else if (response.status === 400) {
        const errorData = await response.json();
        if (errorData.detail && errorData.detail[0].msg.includes("already exists")) {
          setError("A user with this email already exists.");
          setErrorClass("existing-email-error");
        } else {
          setError("Registration failed. Please check your inputs.");
          setErrorClass("");
        }
        setHasError(true);
      } else {
        const errorData = await response.json();
        setError(errorData.detail[0].msg);
        setErrorClass("");
        setHasError(true);
      }
    } catch (error) {
      // Clear any existing error before setting a new one
      setError("");
      setError("A user with this email already exists");
      setErrorClass("existing-email-error");
      setHasError(true);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
      <>
        {!isLoading && (
            <div className="header-container-white">
              <img src={HeaderImagePhoto} alt="Header" className="header-image-white"/>
              <img src={HeaderImage} alt="Header" className="header-image"/>
            </div>
        )}

        <center>
          {isLoading && (
              <img src={HeaderImageWhite} alt="Loading..." className="loading-indicator"/>
          )}
        </center>

        <div className={`card-register ${isLoading ? 'loading' : ''}`}>
          <form onSubmit={validate}>
            <center><h2>Register</h2></center>
            <InputField
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                borderColor={emailColor}
            />
            <InputField
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                showPassword={showPassword}
                togglePasswordVisibility={togglePasswordVisibility}
            />
            <InputField
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                showPassword={showConfirmPassword}
                togglePasswordVisibility={toggleConfirmPasswordVisibility}
                error={errorConfirmPassword}
                errorGmail={errorEmail}
                borderColor={confirmPasswordColor}
            />
            {error && <p className={errorClass}>{error}</p>}
            <button
                className={`submit-btn ${hasError ? 'error-margin' : ''}`}
                type="submit"
                disabled={isLoading} // Disable button when loading
            >
              {isLoading ? "Processing..." : "Get Started"}
            </button>
            <p className="login-link" onClick={() => navigate("/login")}>
              Already have an account? Log in
            </p>
          </form>
        </div>
      </>
  );
}

export default Register;
