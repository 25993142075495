import React from 'react';
import SidebarLayout from './SidebarLayout';
import Header from "../../components/Header";
import '../../styles/sidebar.scss'
import useAuthCheck from "../../webSocket/component/useAuthCheck";

const PullRequests = () => {
    useAuthCheck();


    return (
        <>
            <Header />
            <SidebarLayout>
                <div className="pull-requests-page">🔔 This is the Pull Requests Page.</div>
            </SidebarLayout>
        </>
    );
};

export default PullRequests;
