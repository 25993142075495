import Layout from "./components/Layout";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./register/Register";
import Login from "./register/Login";
import VerifyEmail from "./email/VerifyEmail";
import ConfirmEmail from "./email/ConfirmEmail";
import ConnectTelegram from "./telegram/ConnectTelegram";
import ConfirmTelegram from "./telegram/ConfirmTelegram";
import Congratulations from "./congratulation/Congratulations";
import Issues from "./pages/Issues";
import PullRequests from "./pages/page/PullRequests";
import React from "react";
import PageOne from "./pages/PageOne";
import AccountManagement from "./pages/page/AccountManagement";
import UserProfile from "./pages/page/UserProfile";
import Password from "./telegram/Password/Password";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/verify_email" element={<VerifyEmail />} />
            <Route path="/confirm-email" element={<ConfirmEmail />} />
            <Route path="/connect-telegram" element={<ConnectTelegram />} />
            <Route path="/confirm-telegram" element={<ConfirmTelegram />} />
            <Route path="/congratulations" element={<Congratulations />} />
            <Route path="/pull-requests" element={<PullRequests />} />
            <Route path="/password" element={<Password />} />

            <Route
                path="/*"
                element={
                    <Layout>
                        <Routes>
                            <Route path="/home" element={<Home />} />
                            <Route path="/" element={<Home />} />
                            <Route path="/settings" element={<Issues />} />
                            <Route path="/analytics" element={<PageOne />} />
                            <Route path="/accountManagement" element={<AccountManagement />} />
                            <Route path="/userProfile" element={<UserProfile />} />


                        </Routes>
                    </Layout>
                }
            />
        </Routes>
    );
}

export default App;
