import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../telegram/telegram.css';
import useFlexCenter from '../input/useFlexCenter';
import HeaderImagePhoto from "../icon/img_29.png";
import HeaderImage from "../icon/img_30.png";
import useAuthCheck from "../webSocket/component/useAuthCheck";

const Congratulations = () => {
    useAuthCheck();

    useFlexCenter('flex-center');
    const navigate = useNavigate();

    return (
        <>
            <div className="header-container-white-telegram">
                <img src={HeaderImagePhoto} alt="Header" className="header-image-white"/>
                <img src={HeaderImage} alt="Header" className="header-image"/>
            </div>
        <div className="confirm-telegram-card">
            <h1>Congratulations</h1>
            <p>You have successfully registered on our web app, now you can use all Noisefilter functions</p>
            <button className="submit-btn" onClick={() => navigate("/home")}>Get Started</button>
        </div>
            </>
    );
};

export default Congratulations;
