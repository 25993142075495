class AuthService {
    static async refreshToken() {
        try {
            const token = localStorage.getItem('refresh_token'); // Retrieve the refresh token from localStorage

            if (!token) {
                console.error("No refresh token found, redirecting to login...");
                AuthService.logout(); // Log out the user if no refresh token is found
                return null;
            }

            const response = await fetch('https://noisefilter.app/api/token/refresh/', {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Correct capitalization for Bearer token
                },
                body: JSON.stringify({}),
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('access_token', data.access_token);
                return data.access_token;
            } else {
                console.error("Failed to refresh token:", response.statusText);
                AuthService.logout(); // Log out the user if token refresh fails
                return null;
            }
        } catch (error) {
            console.error("Error refreshing token:", error);
            AuthService.logout(); // Log out the user on error
            return null;
        }
    }

    static logout() {
        localStorage.clear(); // Clear all localStorage items
        window.location.href = '/login'; // Redirect to login page
    }
}

export default AuthService;
