


export class FilterWebSocketHandler {
    constructor(sendMessage, setUnreadCount, activeTab) {
        this.sendMessage = sendMessage;
        this.setUnreadCount = setUnreadCount;
        this.activeTab = activeTab;
    }

    resetFilters(filters) {
        // console.log(filters)
        if (filters && this.activeTab === 'all') {
            const filtersObject = {
                command:
                    filters.command ||
                    (this.isEmptyFilters(filters) ? 'reset_filters' : null),
                username: filters.username?.length > 0 ? filters.username : null,
                chat_title: filters.chat_title?.length > 0
                    ? filters.chat_title
                    : null,
                content: filters.content?.length > 0 ? filters.content : null,
                startswith: filters.startswith?.length > 0
                    ? filters.startswith
                    : null,
            };

            this.sendMessage(JSON.stringify(filtersObject));
            this.setUnreadCount(0); // Reset unread count when filters are applied
        }
    }

    isEmptyFilters(filters) {
        return (
            filters.username?.length === 0 &&
            filters.chat_title?.length === 0 &&
            filters.content?.length === 0 &&
            filters.startswith?.length === 0
        );
    }
}
