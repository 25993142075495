import React, { useEffect, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import classNames from 'classnames';
import { Link, useNavigate, useLocation } from "react-router-dom";
import logoLight from "../icon/img_1.png";
import logoDark from "../icon/img_16.png";
import notificationLight from "../icon/img_31.png";
import notificationDark from "../icon/img_32.png";
import notificationLightWithMessages from "../icon/img_7.png";
import notificationDarkWithMessages from "../icon/img_33.png";

import sun from "../icon/img_6.png";
import moon from "../icon/img_8.png";
import { NotificationWebSocket } from "../webSocket/NotificationWebSocket";
import DOMPurify from "dompurify";
import classes from "./Header.module.scss";

const Header = () => {
    const navigate = useNavigate();
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [themeIcon, setThemeIcon] = useState(sun);
    const [logo, setLogo] = useState(logoLight);
    const [notificationIcon, setNotificationIcon] = useState(notificationLight);
    const [notifications, setNotifications] = useState([]);
    const [showNotifications, setShowNotifications] = useState(false);
    const [readNotifications, setReadNotifications] = useState([]);
    const [error, setError] = useState(null);
    const [username, setUsername] = useState(localStorage.getItem('username'));




    useEffect(() => {
        const unreadNotifications = notifications.filter(notification => !readNotifications.includes(notification.id)).length;

        // Оновлюємо іконку залежно від кількості непрочитаних повідомлень
        if (unreadNotifications > 0) {
            setNotificationIcon(isDarkTheme ? notificationDarkWithMessages : notificationLightWithMessages);
        } else {
            setNotificationIcon(isDarkTheme ? notificationDark : notificationLight);
        }
    }, [readNotifications, notifications, isDarkTheme]);





    // Listening for changes in localStorage
    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'username') {
                setUsername(localStorage.getItem('username'));
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    // Handle window resize
    useEffect(() => {
        const handleResize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        const debounceResize = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(handleResize, 150);
        };

        let timeoutId;
        window.addEventListener('resize', debounceResize);
        return () => {
            window.removeEventListener('resize', debounceResize);
            clearTimeout(timeoutId);
        };
    }, []);

    // Close menu if the window width is greater than 768px
    useEffect(() => {
        if (size.width > 768 && menuOpen) {
            setMenuOpen(false);
        }
    }, [size.width, menuOpen]);

    // Cleanup scroll-lock class on component unmount
    useEffect(() => {
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    const menuToggleHandler = () => {
        setMenuOpen((prev) => !prev);
        if (!menuOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    };

    const themeToggleHandler = () => {
        setIsDarkTheme((prev) => !prev);
        setThemeIcon((prev) => (prev === sun ? moon : sun));
        setLogo((prev) => (prev === logoLight ? logoDark : logoLight));
        setNotificationIcon((prev) => (prev === notificationLight ? notificationDark : notificationLight));
        document.body.classList.toggle('dark-theme');
    };

    const fetchNotifications = async () => {
        try {
            const response = await fetch("https://noisefilter.app/api/notifications/user/me", {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
                    "Accept": "application/json",
                },
            });

            if (response.ok) {
                const data = await response.json();
                setNotifications(data);
                const readIds = data.filter(notification => notification.read).map(notification => notification.id);
                setReadNotifications(readIds);
            } else {
                console.error('Failed to fetch notifications');
            }
        } catch (err) {
            console.error('Error fetching notifications:', err);
            setError('Failed to load notifications');
        }
    };

    const toggleNotifications = async () => {
        if (!showNotifications) {
            try {
                await fetchNotifications();
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        }
        setShowNotifications((prev) => !prev);
    };

    const markAsRead = async (id) => {
        if (!readNotifications.includes(id)) {
            const updatedReadNotifications = [...readNotifications, id];
            setReadNotifications(updatedReadNotifications);

            try {
                const response = await fetch("https://noisefilter.app/api/notifications/user/me/mark_as_read", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem('access_token')}`
                    },
                    body: JSON.stringify({ notification_ids: [id] })
                });

                if (response.ok) {
                    // Оновлюємо іконку одразу після прочитання повідомлення
                    const unreadNotifications = notifications.filter(notification => !updatedReadNotifications.includes(notification.id)).length;

                    if (unreadNotifications > 0) {
                        setNotificationIcon(isDarkTheme ? notificationDarkWithMessages : notificationLightWithMessages);
                    } else {
                        setNotificationIcon(isDarkTheme ? notificationDark : notificationLight);
                    }
                } else {
                    console.error('Failed to mark notification as read');
                    setReadNotifications(readNotifications); // Revert if failed
                }
            } catch (err) {
                console.error('Error:', err);
                setReadNotifications(readNotifications); // Revert if failed
            }
        }
    };


    const getLinkClassName = (path) => {
        return location.pathname === path ? `${classes.active}` : '';
    };

    const handleLogout = () => {
        localStorage.clear();
        navigate("/login");
    };

    const sortedNotifications = notifications.map(notification => ({
        ...notification,
        read: readNotifications.includes(notification.id)
    })).sort((a, b) => a.read - b.read);

    const headerClasses = classNames(classes.header, {
        [classes.headerDark]: isDarkTheme,
    });

    return (
        <header className={headerClasses}>
            <div className={classes.header__content}>
                <Link to="/home">
                    <img src={logo} alt="NoiseFilter Logo" />
                </Link>

                <nav
                    className={`${classes.header__content__nav} ${menuOpen && size.width < 768 ? classes.isMenu : ""}`}
                >
                    <ul>
                        <li>
                            <Link to="/home" onClick={menuToggleHandler} className={getLinkClassName("/home")}>
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to="/analytics" onClick={menuToggleHandler} className={getLinkClassName("/analytics")}>
                                Analytics
                            </Link>
                        </li>
                        <li>
                            <Link to="/settings" onClick={menuToggleHandler} className={getLinkClassName("/settings")}>
                                Settings
                            </Link>
                        </li>
                        {size.width <= 768 && menuOpen && username && (
                            <li className={classes.mobileUsername}>

                                <Link to="/userProfile" onClick={menuToggleHandler}>
                                    <span>@{username}</span>
                                </Link>
                            </li>
                        )}
                        {size.width <= 768 && (
                            <li>
                                <Link to="/login" onClick={() => {
                                    menuToggleHandler();
                                    handleLogout();
                                }} className={`${classes.logoutLink} ${getLinkClassName("/login")}`}>
                                    Log out
                                </Link>
                            </li>
                        )}
                    </ul>
                </nav>

                <div className={classes.header__content__icons}>
                    {size.width > 768 && username && (
                        <span className={classes.username} onClick={() => navigate('/userProfile')}>
                            @{username}
                        </span>
                    )}

                    <img
                        src={themeIcon}
                        alt="Theme Toggle Icon"
                        className={classes.icon}
                        onClick={themeToggleHandler}
                        aria-label="Toggle theme"
                    />

                    <div className={classes.notificationWrapper}>
                        <img
                            src={notificationIcon}
                            alt="Notification Icon"
                            className={classes.icon}
                            onClick={toggleNotifications}
                        />
                        {showNotifications && (
                            <div className={classes.notificationsDropdown}>
                                {sortedNotifications.length > 0 && (
                                    <div className={classes.notificationsHeader}>
                                        <p>Notifications</p>
                                        <button className={classes.moreOptionsButton}>
                                            <span className={classes.moreOptionsIcon}>•••</span>
                                        </button>
                                    </div>
                                )}
                                {error ? (
                                    <p>{error}</p>
                                ) : sortedNotifications.length === 0 ? (
                                    <p>No notifications</p>
                                ) : (
                                    <>
                                        <ul className={classes.notificationsList}>
                                            {sortedNotifications.map((notification) => (
                                                <li
                                                    key={notification.id}
                                                    onClick={() => markAsRead(notification.id)}
                                                    className={
                                                        notification.read
                                                            ? classes.readNotification
                                                            : classes.unreadNotification
                                                    }
                                                >
                                                    {!notification.read && (
                                                        <span className={classes.notificationCircle}></span>
                                                    )}
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: DOMPurify.sanitize(notification.text),
                                                        }}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                        <button className={classes.clearAllButton}>
                                            CLEAR ALL
                                        </button>
                                    </>
                                )}
                            </div>
                        )}
                    </div>

                    {window.innerWidth >= 768 && (
                        <Link to="/login" onClick={() => {
                            menuToggleHandler();
                            handleLogout();
                        }} className={`${classes.logoutLink} ${getLinkClassName("/login")}`}>
                            Log out
                        </Link>
                    )}
                </div>

                <div className={classes.header__content__toggle}>
                    {!menuOpen ? (
                        <BiMenuAltRight onClick={menuToggleHandler} />
                    ) : (
                        <AiOutlineClose onClick={menuToggleHandler} />
                    )}
                </div>
            </div>

            {/* Notification WebSocket Component */}
            <NotificationWebSocket setNotifications={setNotifications} />
        </header>
    );
};

export default Header;
