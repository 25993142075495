import React, { useState } from 'react';
import { FilterInput } from '../component/FilterInput';
import { TagList } from '../component/TagList';
import '../main.scss';
import '../../icon.scss';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import { TextField, Tooltip, IconButton } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import dayjs from "dayjs";
import useAuthCheck from "../component/useAuthCheck";

export function FilterDelete({ onApplyFilters, isDarkTheme }) {
    useAuthCheck();




    const [username, setUsername] = useState('');
    const [chat, setChat] = useState('');
    const [chats, setChats] = useState([]);
    const [content, setContent] = useState('');
    const [contentList, setContentList] = useState([]);
    const [startsWith, setStartsWith] = useState('');
    const [startsWithList, setStartsWithList] = useState([]);
    const [usernames, setUsernames] = useState([]);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [chatError, setChatError] = useState('');

    const removeUsername = (usernameToRemove) => {
        setUsernames(usernames.filter((user) => user !== usernameToRemove));
    };

    const removeContent = (contentToRemove) => {
        setContentList(contentList.filter((item) => item !== contentToRemove));
    };

    const removeStartsWith = (startsWithToRemove) => {
        setStartsWithList(startsWithList.filter((item) => item !== startsWithToRemove));
    };

    const removeChat = (chatTitleToRemove) => {
        setChats(chats.filter((chat) => chat.title !== chatTitleToRemove));
    };


    const addUsername = (selectedUsername = username) => {
        if (selectedUsername && !usernames.includes(selectedUsername)) {
            setUsernames([...usernames, selectedUsername]);
            setUsername(''); // Clear the input after adding
        }
    };

    const addChat = (selectedChatTitle = chat, selectedChatId) => {
        if (selectedChatTitle.trim() && !chats.find(chat => chat.id === selectedChatId)) {
            setChats([...chats, { title: selectedChatTitle.trim(), id: selectedChatId || selectedChatTitle.trim() }]);
            setChat(''); // Reset the chat input field
            setChatError(''); // Clear any previous error
        }
    };


    const addContent = () => {
        if (content && !contentList.includes(content)) {
            setContentList([...contentList, content]);
            setContent('');
        }
    };

    const addStartsWith = () => {
        if (startsWith && !startsWithList.includes(startsWith)) {
            setStartsWithList([...startsWithList, startsWith]);
            setStartsWith('');
        }
    };

    const handleDeleteClick = async () => {
        // Add any remaining inputs to their respective lists, ensuring no duplicates
        if (username.trim() && !usernames.includes(username.trim())) {
            setUsernames((prev) => [...prev, username.trim()]);
        }
        if (chat.trim() && !chats.some(c => c.id === chat.trim())) {
            setChats((prev) => [...prev, { title: chat.trim(), id: chat.trim() }]);
        }
        if (content.trim() && !contentList.includes(content.trim())) {
            setContentList((prev) => [...prev, content.trim()]);
        }
        if (startsWith.trim() && !startsWithList.includes(startsWith.trim())) {
            setStartsWithList((prev) => [...prev, startsWith.trim()]);
        }

        // Update local variables for the request
        const updatedUsernames = username.trim() ? [...usernames, username.trim()] : usernames;
        const updatedChats = chat.trim() ? [...chats, { title: chat.trim(), id: chat.trim() }] : chats;
        const updatedContentList = content.trim() ? [...contentList, content.trim()] : contentList;
        const updatedStartsWithList = startsWith.trim() ? [...startsWithList, startsWith.trim()] : startsWithList;

        // Validation before sending the request
        if (updatedChats.length === 0) {
            setChatError('Chat title is required');
            return;
        } else {
            setChatError('');
        }

        const startDateTimestamp = startDate ? startDate.unix() : 0;
        const endDateTimestamp = endDate ? endDate.unix() : 0;

        if (startDateTimestamp >= endDateTimestamp) {
            setErrorMessage('The start date must be earlier than the end date');
            return;
        } else {
            setErrorMessage('');
        }

        const filters = {
            chats: updatedChats.map(chat => chat.id), // Only pass the chat ids
            filters: {
                username: updatedUsernames.length > 0 ? updatedUsernames : null,
                content: updatedContentList.length > 0 ? updatedContentList : null,
                startswith: updatedStartsWithList.length > 0 ? updatedStartsWithList : null,
            }
        };

        console.log(filters);

        try {
            await onApplyFilters(filters, startDateTimestamp, endDateTimestamp);
            setIsConfirmed(true);

            // Reset state after 2 seconds
            setTimeout(() => {
                setIsConfirmed(false);
            }, 2000);

            // Clear forms after successful request
            setUsername('');
            setChat('');
            setContent('');
            setStartsWith('');

        } catch (error) {
            console.error('Error:', error);
        }
    };



    return (
        <div className={`filter-container visible ${isDarkTheme ? 'dark-theme' : ''}`}>
            <div className="filter-header">
                <span className="filter-label">Filter</span>
            </div>
            <div className="filter-section">
                <div className="filter-options">
                    <FilterInput
                        placeholder="Username"
                        value={username}
                        setValue={setUsername}
                        onAdd={addUsername}
                        isAutoComplete={true}
                        fetchType="username" // Fetch chat titles
                    />
                    <TagList items={usernames} onRemove={removeUsername} />

                    <FilterInput
                        placeholder="Content"
                        value={content}
                        setValue={setContent}
                        onAdd={addContent}
                    />
                    <TagList items={contentList} onRemove={removeContent} />

                    <FilterInput
                        placeholder="Starts with"
                        value={startsWith}
                        setValue={setStartsWith}
                        onAdd={addStartsWith}
                    />
                    <TagList items={startsWithList} onRemove={removeStartsWith} />

                    <div className="filter-input-wrapper">
                        <FilterInput
                            placeholder="Enter chat title"
                            value={chat}
                            setValue={setChat}
                            onAdd={addChat}
                            isAutoComplete={true}
                            isRequired={true}
                            fetchType="chat" // Fetch chat titles

                        />
                        {chat === '' && <span className="required-asterisk">*</span>}
                    </div>
                    <TagList items={chats.map(chat => chat.title)} onRemove={removeChat} />
                </div>
                <div className="date-picker-container" style={{display: 'flex', gap: '1rem', flexDirection: 'column'}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                        <DateTimePicker
                            label="Start Date & Time"
                            value={startDate}
                            onChange={(newValue) => {
                                const selectedDate = dayjs(newValue);
                                setStartDate(selectedDate);
                            }}
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    className={isDarkTheme ? 'dark-input' : ''}
                                />
                            )}
                            inputFormat="DD/MM/YYYY HH:mm"
                            timeFormat="HH:mm"
                        />
                        <DateTimePicker
                            label="End Date & Time"
                            value={endDate}
                            onChange={(newValue) => {
                                const selectedDate = dayjs(newValue);
                                setEndDate(selectedDate);
                            }}
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    className={isDarkTheme ? 'dark-input' : ''}
                                />
                            )}
                            inputFormat="DD/MM/YYYY HH:mm"
                            timeFormat="HH:mm"
                        />
                    </LocalizationProvider>
                    {errorMessage && <div className="existing-email-error">{errorMessage}</div>}
                    {chatError && <div className="existing-email-error">{chatError}</div>}
                    <button
                        className={`get-deleted-btn-filter ${isConfirmed ? 'confirmed' : ''}`}
                        onClick={handleDeleteClick}
                        style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}} // Flexbox to align text and icon
                    >
                        {isConfirmed ? 'Confirm Deleted ✔' : 'Get Deleted'}
                        <Tooltip
                            title="Your request may take more than a minute"
                            placement="top"
                            arrow
                        >
                            <HelpOutlineIcon
                                fontSize="small"
                                style={{color: '#FFFFFF'}} // White color for the icon
                            />
                        </Tooltip>
                    </button>
                </div>
            </div>
        </div>
    );
}
